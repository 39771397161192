import { useEffect } from "react";
import { useMap } from "./Mapbox";

const source = {
  source: "composite",
  sourceLayer: "counties"
};

const CountyData = ({ id, ...data }) => {
  const map = useMap();

  useEffect(() => {
    return () => map.removeFeatureState({ ...source, id });
  }, [id, map]);

  useEffect(() => {
    map.setFeatureState({ ...source, id }, data);
  });

  return null;
};

export default CountyData;
