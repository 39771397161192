import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import ReactGA from "react-ga";

import useQuerySettings from "./useQuerySettings";

const makeLocalLogger = type =>
  process.env.REACT_APP_DEBUG
    ? (...args) => console.log(`GA ${type}:`, ...args)
    : () => {};

const loggerFor = type =>
  process.env.NODE_ENV === "production" ? ReactGA[type] : makeLocalLogger(type);

const logGAEvent = debounce(loggerFor("event"), 100);
const logGAPageview = loggerFor("pageview");

export default day => {
  const { pathname } = useLocation();
  const [{ field, perCapita }] = useQuerySettings();

  useEffect(() => {
    day &&
      logGAEvent({
        category: "history-slider",
        action: "change",
        value: day
      });
  }, [day]);

  useEffect(() => {
    pathname === "/"
      ? logGAPageview(
          ["/us", field, perCapita && "per-capita"].filter(p => p).join("/")
        )
      : logGAPageview(pathname);
  }, [field, pathname, perCapita]);
};
