import React, { memo, useCallback } from "react";
import useDimensions from "react-use-dimensions";
import classNames from "classnames";

import TotalsGraph from "../TotalsGraph";
import DataSelector from "../DataSelector";
import Toggle from "../Toggle";
import { addDaysTo } from "../../utilities/dates";
import useQuerySettings from "../../hooks/useQuerySettings";

import "../../panel.css";
import {
  dataPanel,
  header,
  summaryBox,
  totals,
  controlsBox
} from "./DataPanel.module.css";

const summaryConfig = {
  cases: {
    field: "caseTotal",
    message: count => `Total Cases: ${count}`,
    color: "#EB0A6F"
  },
  deaths: {
    field: "deathTotal",
    message: count => `Total Deaths: ${count}`,
    color: "rgba(255,9,6,1)"
  }
};

const formatDate = new Intl.DateTimeFormat("en-US", { dateStyle: "long" })
  .format;

const DataPanel = ({ summaries = [], domain, day, setDay }) => {
  const [ref, { width, x }] = useDimensions();
  const [{ field, perCapita }, { replaceQuery }] = useQuerySettings();
  const [startDate, endDate, numDays] = domain;
  const summary = summaries[day];
  const config = summaryConfig[field];

  const setField = useCallback(f => replaceQuery(q => ({ ...q, f })), [
    replaceQuery
  ]);

  const togglePerCapita = useCallback(() => {
    replaceQuery(q => ({ ...q, pc: !q.pc }));
  }, [replaceQuery]);

  return (
    <section className={classNames(dataPanel, "panel")}>
      <div className={header} ref={ref}>
        <div className={summaryBox}>
          <h1>United States</h1>
          <span className={totals}>
            {summary
              ? config.message(summary[config.field].toLocaleString())
              : "Loading..."}
          </span>
        </div>
        <div className={controlsBox}>
          <DataSelector value={field} onChange={setField} />
          <Toggle checked={perCapita} onChange={togglePerCapita}>
            per capita
          </Toggle>
        </div>
      </div>
      <h2>{formatDate(addDaysTo(startDate, day))}</h2>
      {width && (
        <TotalsGraph
          startDate={startDate}
          endDate={endDate}
          numDays={numDays}
          data={summaries}
          value={day}
          onChange={setDay}
          width={width}
          height={window.screen.width > 1000 ? 75 : 40}
          left={x}
          {...config}
        />
      )}
    </section>
  );
};

export default memo(DataPanel);
