import React from "react";
import { Link } from "react-router-dom";

import { overlay, backdrop, modal } from "./Modal.module.css";

const Modal = ({ children }) => {
  return (
    <div className={overlay}>
      <Link className={backdrop} to="/" />
      <section className={modal}>{children}</section>
    </div>
  );
};

export default Modal;
