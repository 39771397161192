import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback
} from "react";
import debounce from "lodash.debounce";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

export const MapboxContext = React.createContext();
export const useMap = () => useContext(MapboxContext);

const Mapbox = ({ children, onMove, attribution, ...options }) => {
  const container = useRef();
  const [map, setMap] = useState(null);
  const debouncedMove = useCallback(debounce(onMove, 100), [onMove]);

  useEffect(() => {
    const mapInstance = new mapboxgl.Map({
      container: container.current,
      style: process.env.REACT_APP_MAPBOX_STYLE,
      attributionControl: !attribution,
      ...options
    });

    if (attribution) {
      mapInstance.addControl(
        new mapboxgl.AttributionControl({
          compact: false,
          customAttribution: attribution
        })
      );
    }

    mapInstance.on("load", () => setMap(mapInstance));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map) {
      const moveHandler = () => debouncedMove(map);
      map.on("move", moveHandler);

      return () => {
        map.off("move", moveHandler);
      };
    }
  }, [map, debouncedMove]);

  return (
    <MapboxContext.Provider value={map}>
      <div ref={container} style={{ height: "100%" }} />
      {map ? children : null}
    </MapboxContext.Provider>
  );
};

Mapbox.defaultProps = {
  onMove: () => {}
};

export default Mapbox;
