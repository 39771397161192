import React, { memo, useCallback, useMemo } from "react";
import { LinePath } from "@vx/shape";
import { scaleTime, scaleLinear } from "@vx/scale";
import { useSpring, animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import { Play, Pause } from "react-feather";

import useAnimationFrame from "../../hooks/useAnimationFrame";
import DateAxis from "./DateAxis";
import { graph, playButton } from "./TotalsGraph.module.css";

// Constants
const margin = 10;
const x = d => d.date;
const tickFormat = d => d.getDate();
const scrubberWidth = 2.5;

const TotalsGraph = ({
  width: originalWidth,
  height,
  left: originalLeft,
  startDate,
  endDate,
  numDays,
  data = [],
  field,
  color,
  value,
  onChange
}) => {
  const width = originalWidth - 30;
  const left = originalLeft + 30;
  const xMax = width - margin;
  const yMax = height;
  const y = useCallback(d => d[field], [field]);

  const moveScale = useMemo(
    () =>
      scaleLinear({
        rangeRound: [0, numDays],
        domain: [margin, xMax],
        clamp: true
      }),
    [numDays, xMax]
  );

  const [scrubber, setScrubber] = useSpring(() => {
    return { x: moveScale.invert(value) - scrubberWidth / 2 };
  });

  const [cursor, setCursor] = useSpring(() => {
    return { opacity: 0, x: 0 };
  });

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [margin, xMax],
        domain: [startDate, endDate]
      }),
    [endDate, startDate, xMax]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        domain: [0, Math.max(...data.map(y))]
      }),
    [data, y, yMax]
  );

  const onFrame = useCallback(
    ({ progress }) => {
      const day = (progress * numDays) % numDays;
      setScrubber({
        x: moveScale.invert(day) - scrubberWidth / 2,
        immediate: true
      });
      onChange(Math.round(day));
    },
    [moveScale, numDays, onChange, setScrubber]
  );

  const { playing, play, pause, progress } = useAnimationFrame(onFrame, 15);

  const bind = useGesture({
    onDrag: ({ down, first, initial: [initX], movement: [dx], memo }) => {
      const x = initX + dx - left;

      const day = moveScale(x);
      pause();
      progress(day / numDays);
      setScrubber({
        x: down
          ? Math.min(Math.max(margin, x), width - margin)
          : moveScale.invert(day) - scrubberWidth / 2,
        immediate: down && !first
      });
      day !== memo && onChange(day);
      return day;
    },
    onHover: ({ active }) =>
      setCursor({ opacity: active ? 0.4 : 0, immediate: true }),
    onMove: ({ dragging, xy: [x] }) => {
      setCursor({
        x: moveScale.invert(moveScale(x - left)) - scrubberWidth / 2,
        opacity: dragging ? 0 : 0.4,
        immediate: true
      });
    }
  });

  const PlaybackIcon = playing ? Pause : Play;

  return (
    <div className={graph}>
      <button className={playButton} onClick={playing ? pause : play}>
        <PlaybackIcon size={30} fill="currentColor" />
      </button>
      <svg
        width={width}
        height={height + 40}
        {...bind()}
        style={{ cursor: "grab" }}
      >
        <LinePath
          data={data}
          x={d => xScale(x(d))}
          y={d => yScale(y(d))}
          stroke={color}
          strokeWidth={2.5}
        />

        <animated.rect
          {...cursor}
          y={0}
          width={scrubberWidth}
          height={height + 5}
          fill="#5CECFF"
        />

        <animated.rect
          {...scrubber}
          y={0}
          width={scrubberWidth}
          height={height + 5}
          fill="#5CECFF"
        />

        <DateAxis
          top={height}
          left={0}
          scale={xScale}
          numTicks={numDays}
          tickFormat={tickFormat}
        />
      </svg>
    </div>
  );
};

export default memo(TotalsGraph, (prevProps, nextProps) => {
  const skipKeys = new Set(["value"]);
  return Object.keys(nextProps).every(
    key => skipKeys.has(key) || prevProps[key] === nextProps[key]
  );
});
