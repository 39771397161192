import React, { memo } from "react";
import { AxisBottom } from "@vx/axis";
import { Line } from "@vx/shape";
import { Group } from "@vx/group";

const tickColor = "#fff";
const formatMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format;

const DateAxis = props => (
  <AxisBottom {...props}>
    {axis => (
      <g opacity="0.5">
        <Line
          from={axis.axisFromPoint}
          to={axis.axisToPoint}
          stroke={tickColor}
        />
        {axis.ticks.map((tick, i) => (
          <Group key={`vx-tick-${tick.value}-${i}`}>
            <Line from={tick.from} to={tick.to} stroke={tickColor} />
            {(i === 0 || (+tick.formattedValue + 5) % 6 === 0) &&
              tick.formattedValue !== 31 && (
                <text
                  className="noSelect"
                  fill={tickColor}
                  fontSize={10}
                  fontWeight="bold"
                  textAnchor="middle"
                  transform={`translate(${tick.to.x}, ${tick.to.y +
                    5 +
                    axis.tickLength})`}
                >
                  {tick.formattedValue}
                </text>
              )}
            {(i === 0 || tick.formattedValue === 1) && (
              <text
                className="noSelect"
                fill={tickColor}
                fontSize={10}
                fontWeight="bold"
                transform={`translate(${tick.to.x - 2}, ${tick.to.y +
                  20 +
                  axis.tickLength})`}
              >
                {formatMonth(tick.value)}
              </text>
            )}
          </Group>
        ))}
      </g>
    )}
  </AxisBottom>
);

export default memo(DateAxis);
