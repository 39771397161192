import { useQueryParams } from "react-router-query-hooks";

const parseOptions = { parseNumbers: true, parseBooleans: true };
const validFields = { cases: "cases", deaths: "deaths" };

const parseQuery = ({ f, pc, ...query }) => ({
  ...query,
  field: validFields[f] || "cases",
  perCapita: !!pc
});

export default () => {
  const [query, setters] = useQueryParams(parseOptions);
  return [parseQuery(query), setters];
};
