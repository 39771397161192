import React, { memo, useState, useEffect, useCallback } from "react";
import { Popper } from "react-popper";

import { useMap } from "./Mapbox";
import CountyPopover from "./CountyPopover";

const setHover = (map, id, hover) => {
  map.setFeatureState(
    { id, source: "composite", sourceLayer: "counties" },
    { hover }
  );
  // map.setFeatureState(
  //   { id, source: "counties", sourceLayer: "county_centroid_blocks" },
  //   { hover }
  // );
};

const noCounty = {};

const HoverLayer = () => {
  const map = useMap();
  const [{ x, y }, setMouse] = useState({ x: 200, y: 200 });
  const [hoveredCounty, setHoveredCounty] = useState({});
  const unSelectCounty = useCallback(() => {
    setHoveredCounty(noCounty);
  }, []);

  const { id } = hoveredCounty;

  useEffect(() => {
    map.on("mousemove", "counties", e => {
      if (e.features.length) {
        setMouse({ x: e.originalEvent.clientX, y: e.originalEvent.clientY });

        const { id, properties } = e.features[0];
        const data = map.getFeatureState({
          id,
          source: "composite",
          sourceLayer: "counties"
        });
        setHoveredCounty({ id, ...properties, ...data });
      }
    });

    map.on("mouseleave", "counties", unSelectCounty);
    map.on("move", unSelectCounty);
  }, [map, unSelectCounty]);

  useEffect(() => {
    if (id) {
      map.getCanvas().style.cursor = "pointer";
      setHover(map, id, true);
      return () => {
        map.getCanvas().style.cursor = "grab";
        setHover(map, id, false);
      };
    }
  }, [id, map]);

  return (
    id && (
      <Popper
        placement="bottom-start"
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 15]
            }
          }
        ]}
        referenceElement={{
          getBoundingClientRect: () => ({
            width: 0,
            height: 0,
            top: y,
            right: x,
            bottom: y,
            left: x
          })
        }}
      >
        {({ ref, style, placement }) => (
          <div
            ref={ref}
            style={{ ...style, zIndex: 1 }}
            data-placement={placement}
          >
            <CountyPopover {...hoveredCounty} />
          </div>
        )}
      </Popper>
    )
  );
};

export default memo(HoverLayer);
